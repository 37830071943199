import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import MaterialTable from "material-table";

import ViewListIcon from "@material-ui/icons/ViewList";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { Paper } from "@material-ui/core";

import { Refresh } from "@material-ui/icons";

import styles from "../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import Loader from "../../../components/Loader/index";
import { GetEscalationListAction } from "./actions/TaskActions";

class EscalationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    this.props.GetEscalationListAction();
  };

  componentDidUpdate = (prevProps) => {
    if (
      this.props.escalationList !== undefined &&
      this.props.escalationList !== null &&
      this.props.escalationList !== prevProps.escalationList
    ) {
      this.setState({
        data: this.props.escalationList,
      });
    }
  };

  render() {
    const { loading } = this.props;
    const { data } = this.state;

    return (
      <>
        {loading && <Loader open={true} loaderMessage="Fetching..." />}
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader>
                <CardIcon color="rose" icon>
                  <ViewListIcon />
                </CardIcon>
                <h4>Escalation/Overdue List</h4>
              </CardHeader>
              <CardBody>
                <MaterialTable
                  components={{
                    Container: (props) => <Paper {...props} elevation={0} />,
                  }}
                  title=""
                  columns={[
                    {
                      title: "Service Name",
                      field: "serviceName",
                    },
                    {
                      title: "TO Email",
                      field: "taskOwner.email",
                    },
                    {
                      title: "User First Name",
                      field: "user.firstName",
                    },
                    {
                      title: "User Last Name",
                      field: "user.lastName",
                    },
                    {
                      title: "User Email",
                      field: "user.email",
                    },
                  ]}
                  data={data}
                  options={{
                    actionsColumnIndex: -1,
                    filtering: true,
                    sorting: true,
                    headerStyle: {
                      backgroundColor: "#ccc",
                      color: "#000",
                      fontWeight: 600,
                    },
                    rowStyle: {
                      backgroundColor: "#EEE",
                    },
                  }}
                  actions={[
                    {
                      icon: Refresh,
                      tooltip: "Refresh List",
                      isFreeAction: true,
                      onClick: () => this.loadData(),
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

EscalationList = reduxForm({
  form: "EscalationListForm",
  enableReinitialize: true,
})(EscalationList);

function mapStateToProps(state) {
  return {
    loading: state.taskReducer.loading,
    escalationList:
      state.taskReducer.escalationList !== null &&
      state.taskReducer.escalationList !== undefined &&
      state.taskReducer.escalationList.data,
    loggedInUserDetails:
      state.loginReducer.loggedInUserDetails !== null &&
      state.loginReducer.loggedInUserDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        GetEscalationListAction,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EscalationList));
