/**
 @desc
 * Centralized unique actions for banner Module.
 */

import {
  actionCreator,
  checkHttpStatus,
  jsonApiHeader,
  showErrorMessage,
  TaskActionTypes,
  POST_CREATE_TASK_API,
  GET_TASK_LIST_API,
  POST_UPDATE_TASK_API,
  POST_TASK_DELETE_API,
  GET_ESCALATION_LIST_API,
  GET_TASK_BY_USER_ID_API,
} from "../constants/index";
import {
  showSuccessMessage,
  TOKEN_EXPIRY_MESSAGE,
} from "../../../../shared/utility";

export const PostTaskCreateAction = (postData) => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.token;
    dispatch(actionCreator(TaskActionTypes.post_createTask.REQUEST));
    fetch(`${POST_CREATE_TASK_API}`, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(TaskActionTypes.post_createTask.FAILURE, response)
          );
          showErrorMessage(response.message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
          localStorage.clear();
          dispatch(
            actionCreator(TaskActionTypes.post_createTask.FAILURE, response)
          );
          window.location.reload();
        } else {
          dispatch(
            actionCreator(TaskActionTypes.post_createTask.SUCCESS, response)
          );
          showSuccessMessage("Task Status Created Successfully");
          dispatch(GetTaskListAction());
        }
      })
      .catch(function (error) {
        dispatch(actionCreator(TaskActionTypes.post_createTask.FAILURE));
        console.log("error post_createTask ..", error);
      });
  };
};

export const GetTaskListAction = () => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.token;
    dispatch(actionCreator(TaskActionTypes.get_taskList.REQUEST));
    fetch(`${GET_TASK_LIST_API}`, {
      method: "GET",
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(TaskActionTypes.get_taskList.FAILURE, response)
          );
          showErrorMessage(response.message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
          localStorage.clear();
          dispatch(
            actionCreator(TaskActionTypes.get_taskList.FAILURE, response)
          );
          window.location.reload();
        } else {
          dispatch(
            actionCreator(TaskActionTypes.get_taskList.SUCCESS, response)
          );
        }
      })
      .catch(function (error) {
        dispatch(actionCreator(TaskActionTypes.get_taskList.FAILURE));
        console.log("error get_taskList ..", error);
      });
  };
};

export const PostTaskUpdateAction = (postData, taskId) => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.token;
    dispatch(actionCreator(TaskActionTypes.post_updateTask.REQUEST));
    fetch(`${POST_UPDATE_TASK_API}/${taskId}`, {
      method: "PUT",
      body: JSON.stringify(postData),
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(TaskActionTypes.post_updateTask.FAILURE, response)
          );
          showErrorMessage(response.message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
          localStorage.clear();
          dispatch(
            actionCreator(TaskActionTypes.post_updateTask.FAILURE, response)
          );
          window.location.reload();
        } else {
          dispatch(
            actionCreator(TaskActionTypes.post_updateTask.SUCCESS, response)
          );
          showSuccessMessage("Task Status Updated Successfully");
          dispatch(GetTaskListAction());
        }
      })
      .catch(function (error) {
        dispatch(actionCreator(TaskActionTypes.post_updateTask.FAILURE));
        console.log("error post_updateTask ..", error);
      });
  };
};

export const PostTaskDeleteAction = (taskId) => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.token;
    dispatch(actionCreator(TaskActionTypes.post_taskDelete.REQUEST));
    fetch(`${POST_TASK_DELETE_API}/${taskId}`, {
      method: "DELETE",
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(TaskActionTypes.post_taskDelete.FAILURE, response)
          );
          showErrorMessage(response.message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
          localStorage.clear();
          dispatch(
            actionCreator(TaskActionTypes.post_taskDelete.FAILURE, response)
          );
          window.location.reload();
        } else {
          dispatch(
            actionCreator(TaskActionTypes.post_taskDelete.SUCCESS, response)
          );
          showSuccessMessage("Task Deleted Successfully");
          dispatch(GetTaskListAction());
        }
      })
      .catch(function (error) {
        dispatch(actionCreator(TaskActionTypes.post_taskDelete.FAILURE));
        console.log("error post_taskDelete ..", error);
      });
  };
};

export const GetEscalationListAction = () => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.token;
    dispatch(actionCreator(TaskActionTypes.get_escalationList.REQUEST));
    fetch(`${GET_ESCALATION_LIST_API}`, {
      method: "GET",
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(TaskActionTypes.get_escalationList.FAILURE, response)
          );
          showErrorMessage(response.message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
          localStorage.clear();
          dispatch(
            actionCreator(TaskActionTypes.get_escalationList.FAILURE, response)
          );
          window.location.reload();
        } else {
          dispatch(
            actionCreator(TaskActionTypes.get_escalationList.SUCCESS, response)
          );
        }
      })
      .catch(function (error) {
        dispatch(actionCreator(TaskActionTypes.get_escalationList.FAILURE));
        console.log("error get_escalationList ..", error);
      });
  };
};
export const GetUserTaskListAction = (userId) => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.token;
    dispatch(actionCreator(TaskActionTypes.get_taskByUserId.REQUEST));
    fetch(`${GET_TASK_BY_USER_ID_API}?userId=${userId}`, {
      method: "GET",
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(TaskActionTypes.get_taskByUserId.FAILURE, response)
          );
          showErrorMessage(response.message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
          localStorage.clear();
          dispatch(
            actionCreator(TaskActionTypes.get_taskByUserId.FAILURE, response)
          );
          window.location.reload();
        } else {
          dispatch(
            actionCreator(TaskActionTypes.get_taskByUserId.SUCCESS, response)
          );
        }
      })
      .catch(function (error) {
        dispatch(actionCreator(TaskActionTypes.get_taskByUserId.FAILURE));
        console.log("error get_taskByUserId ..", error);
      });
  };
};
