/**
 @desc
 * Centralized unique action types for UAM.
 */

import {
  actionCreator,
  API_URL,
  checkHttpStatus,
  createRequestActionTypes,
  jsonApiHeader,
  showSuccessMessage,
  showErrorMessage,
} from "../../../../shared/utility";

export {
  jsonApiHeader,
  actionCreator,
  checkHttpStatus,
  showSuccessMessage,
  showErrorMessage,
};

export const GET_ALL_USER_API = `${API_URL}/v1/admin/all`;
export const GET_ALL_USER_PORTAL_USER_API = `${API_URL}/v1/admin/users`;
export const POST_CREATE_USER_API = `${API_URL}/v1/admin/auth/users`;
export const PUT_UPDATE_ADMIN_USER_BY_ID_API = `${API_URL}/v1/admin/auth`;
export const POST_BLOCK_UNBLOCK_USER_BY_ID_API = `${API_URL}/v1/admin/auth/update`;
export const POST_UPDATE_ADMIN_USER_PASSWORD_BY_ID_API = `${API_URL}/v1/admin/auth/change`;
export const DELETE_ADMIN_USER_API = `${API_URL}/v1/admin/auth`;

export const UamActionTypes = {
  get_AllUsers: createRequestActionTypes("GET_ALL_USER"),
  post_CreateUser: createRequestActionTypes("POST_CREATE_USER"),
  post_BlockUnblockUser: createRequestActionTypes(
    "POST_BLOCK_UNBLOCK_USER_BY_ID"
  ),
  post_UpdateAdminUserById: createRequestActionTypes(
    "POST_UPDATE_ADMIN_USER_PASSWORD_BY_ID"
  ),
  put_UpdateAdminUserDetailsById: createRequestActionTypes(
    "PUT_UPDATE_ADMIN_USER_BY_ID"
  ),
  delete_AdminUser: createRequestActionTypes("DELETE_ADMIN_USER"),
  get_UserPortalUser: createRequestActionTypes("GET_ALL_USER_PORTAL_USER"),
};
