import React, { Component } from "react";
import { Switch, withRouter, Redirect, Route } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";

import Aux from "../shared/hoc/Auxiliary/Auxiliary";
import { RefreshTokenAction } from "../views/Login/actions/LoginActions";
import Loader from "../components/Loader/index";

class RoutesComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.props.RefreshTokenAction(localStorage.getItem("access_token"));
  }

  componentDidMount = () => {
    const accessToken = localStorage.getItem("access_token");
    if (!this.state.isLoggedIn && accessToken === this.props.token) {
      const isLoggedIn = true;
      this.setState({ isLoggedIn });
    }
  };

  componentDidUpdate = () => {
    const accessToken = localStorage.getItem("access_token");
    if (!this.state.isLoggedIn && accessToken === this.props.token) {
      const isLoggedIn = true;
      this.setState({ isLoggedIn });
    }
  };

  redirectRouteHandler = (role) => {
    if (
      role.toUpperCase() === "ADMIN" ||
      role.toUpperCase() === "CHECKER" ||
      role.toUpperCase() === "MAKER" ||
      role.toUpperCase() === "SUPPORT"
    ) {
      return (
        <Switch>
          <Route exact path="/">
            <Redirect exact from="/" to="/admin/dashboard" />
          </Route>

          <Route path="**">
            <Redirect exact from="*" to="/admin/dashboard" />
          </Route>
        </Switch>
      );
    } else {
      return;
    }
  };

  render() {
    const { isLoggedIn } = this.state;
    const { loading, role } = this.props;
    let routes = (
      <>
        {isLoggedIn ? (
          <Switch>
            <Route path="/admin" component={AdminLayout} />
            {this.redirectRouteHandler(role)}
          </Switch>
        ) : (
          <>
            {loading || localStorage.getItem("access_token") ? (
              <Loader open={true} loaderMessage="Fetching..." />
            ) : (
              <Switch>
                <Route path="/auth" component={AuthLayout} />
                <Redirect to="/auth" />
              </Switch>
            )}
          </>
        )}
      </>
    );
    return <Aux>{routes}</Aux>;
  }
}

function mapStateToProps(state) {
  return {
    loadig: state.loginReducer.loading,
    isLoggedIn: state.loginReducer.isLoggedIn,
    role:
      state.loginReducer.loggedInUserDetails !== null &&
      state.loginReducer.loggedInUserDetails.data.role,
    token:
      state.loginReducer.loggedInUserDetails !== null
        ? state.loginReducer.loggedInUserDetails.token
        : "",
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        RefreshTokenAction,
      },
      dispatch
    ),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RoutesComponent)
);
