/**
 @desc
 * Centralized unique action types for creating task status.
 */

import {
  actionCreator,
  API_URL,
  checkHttpStatus,
  createRequestActionTypes,
  jsonApiHeader,
  showSuccessMessage,
  showErrorMessage,
} from "../../../../shared/utility";

export {
  jsonApiHeader,
  actionCreator,
  checkHttpStatus,
  showSuccessMessage,
  showErrorMessage,
};

export const POST_CREATE_TASK_API = `${API_URL}/v1/task-status`;
export const POST_UPDATE_TASK_API = `${API_URL}/v1/task-status`;
export const GET_TASK_LIST_API = `${API_URL}/v1/task-status`;
export const POST_TASK_DELETE_API = `${API_URL}/v1/task-status`;

export const GET_ESCALATION_LIST_API = `${API_URL}/v1/admin/tasks/escalated`;
export const GET_TASK_BY_USER_ID_API = `${API_URL}/v1/admin/tasks`;

export const TaskActionTypes = {
  post_createTask: createRequestActionTypes("POST_UPLOAD_TASK"),
  post_updateTask: createRequestActionTypes("POST_UPDATE_TASK"),
  get_taskList: createRequestActionTypes("GET_TASK_LIST"),
  post_taskDelete: createRequestActionTypes("POST_TASK_DELETE"),
  get_escalationList: createRequestActionTypes("GET_ESCALATION_LIST"),
  get_taskByUserId: createRequestActionTypes("GET_TASK_BY_USER_ID"),
};
