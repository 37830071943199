import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import MaterialTable from "material-table";

import ViewListIcon from "@material-ui/icons/ViewList";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { Paper } from "@material-ui/core";
import { Refresh } from "@material-ui/icons";

import styles from "../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import { GetUserPortalUserAction } from "./actions/UamActions";
import Loader from "../../../components/Loader/index";

class UserPortalUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { title: "First Name", field: "firstName" },
        { title: "Last Name", field: "lastName" },
        { title: "Email", field: "email" },
      ],
      data: [],
    };
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    this.props.GetUserPortalUserAction();
  };

  componentDidUpdate = (prevProps) => {
    if (
      this.props.userPortalUserList !== undefined &&
      this.props.userPortalUserList !== null &&
      this.props.userPortalUserList !== prevProps.userPortalUserList
    ) {
      this.setState({
        data: this.props.userPortalUserList,
      });
    }
  };

  render() {
    const { loading } = this.props;
    const { columns, data } = this.state;

    return (
      <>
        {loading && <Loader open={true} loaderMessage="Fetching..." />}
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader>
                <CardIcon color="rose" icon>
                  <ViewListIcon />
                </CardIcon>
                <h4>User Portal User Data</h4>
              </CardHeader>
              <CardBody>
                <MaterialTable
                  components={{
                    Container: (props) => <Paper {...props} elevation={0} />,
                  }}
                  title=""
                  columns={columns}
                  data={data}
                  options={{
                    actionsColumnIndex: -1,
                    filtering: true,
                    headerStyle: {
                      backgroundColor: "#ccc",
                      color: "#000",
                      fontWeight: 600,
                    },
                    rowStyle: {
                      backgroundColor: "#EEE",
                    },
                    // grouping: true
                  }}
                  actions={[
                    {
                      icon: Refresh,
                      tooltip: "Refresh List",
                      isFreeAction: true,
                      onClick: () => this.loadData(),
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

UserPortalUser = reduxForm({
  form: "UserPortalUserForm",
  enableReinitialize: true,
})(UserPortalUser);

function mapStateToProps(state) {
  return {
    loading: state.uamReducer.loading,
    userPortalUserList:
      state.uamReducer.userPortalUserList !== null &&
      state.uamReducer.userPortalUserList.data,
    loggedInUserDetails:
      state.loginReducer.loggedInUserDetails !== null &&
      state.loginReducer.loggedInUserDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        GetUserPortalUserAction,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(UserPortalUser));
