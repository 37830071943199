import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Field, reduxForm, change } from "redux-form";

import MaterialTable from "material-table";

import ViewListIcon from "@material-ui/icons/ViewList";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { MenuItem, Paper } from "@material-ui/core";

import { Refresh } from "@material-ui/icons";

import styles from "../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import Loader from "../../../components/Loader/index";
import { GetUserTaskListAction } from "./actions/TaskActions";
import { renderSelectField } from "../../../shared/reduxFields";
import { GetUserPortalUserAction } from "../UAM/actions/UamActions";

class UserTaskList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userTaskList: [],
      userPortalUserList: [],
    };
  }

  componentDidMount = () => {
    this.props.GetUserPortalUserAction();
  };

  userChangeHandler = (event) => {
    let userId = event.target.value;
    this.props.GetUserTaskListAction(userId);
  };

  componentDidUpdate = (prevProps) => {
    if (
      this.props.userPortalUserList !== undefined &&
      this.props.userPortalUserList !== null &&
      this.props.userPortalUserList !== prevProps.userPortalUserList
    ) {
      this.setState({
        userPortalUserList: this.props.userPortalUserList,
      });
    }
    if (
      this.props.userTaskList !== undefined &&
      this.props.userTaskList !== null &&
      this.props.userTaskList !== prevProps.userTaskList
    ) {
      this.setState({
        userTaskList: this.props.userTaskList,
      });
    }
  };

  render() {
    const { loading } = this.props;
    const { userTaskList, userPortalUserList } = this.state;

    return (
      <>
        {loading && <Loader open={true} loaderMessage="Fetching..." />}
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader>
                <CardIcon color="rose" icon>
                  <ViewListIcon />
                </CardIcon>
                <h4>User Tasks List</h4>
              </CardHeader>
              <CardBody>
                <GridItem xs={12} md={6} lg={6}>
                  <Field
                    id="userId"
                    name="userId"
                    // label="Select User"
                    required={true}
                    component={renderSelectField}
                    onChange={(event) => this.userChangeHandler(event)}
                  >
                    {userPortalUserList?.map(({ _id, firstName, lastName }) => (
                      <MenuItem style={{ display: "block" , padding:"10px" }} value={_id} key={_id}>
                        {`${firstName} ${lastName}`}
                      </MenuItem>
                    ))}
                  </Field>
                </GridItem>
                <MaterialTable
                  components={{
                    Container: (props) => <Paper {...props} elevation={0} />,
                  }}
                  title=""
                  columns={[
                    {
                      title: "User First Name",
                      field: "user.firstName",
                    },
                    {
                      title: "User Last Name",
                      field: "user.lastName",
                    },
                    {
                      title: "User Email",
                      field: "user.email",
                    },
                    {
                      title: "TO",
                      field: "taskOwner.email",
                    },
                    {
                      title: "Status",
                      field: "status.name",
                    },
                    {
                      title: "Service Name",
                      field: "serviceName",
                    },
                  ]}
                  data={userTaskList}
                  options={{
                    actionsColumnIndex: -1,
                    filtering: true,
                    sorting: true,
                    headerStyle: {
                      backgroundColor: "#ccc",
                      color: "#000",
                      fontWeight: 600,
                    },
                    rowStyle: {
                      backgroundColor: "#EEE",
                    },
                  }}
                  actions={[
                    {
                      icon: Refresh,
                      tooltip: "Refresh List",
                      isFreeAction: true,
                      onClick: () => this.loadData(),
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

UserTaskList = reduxForm({
  form: "UserTaskListForm",
  enableReinitialize: true,
})(UserTaskList);

function mapStateToProps(state) {
  return {
    loading: state.taskReducer.loading,
    userTaskList:
      state.taskReducer.userTaskList !== null &&
      state.taskReducer.userTaskList !== undefined &&
      state.taskReducer.userTaskList.data,
    userPortalUserList:
      state.uamReducer.userPortalUserList !== null &&
      state.uamReducer.userPortalUserList.data,
    loggedInUserDetails:
      state.loginReducer.loggedInUserDetails !== null &&
      state.loginReducer.loggedInUserDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        GetUserTaskListAction,
        GetUserPortalUserAction,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(UserTaskList));
