import { UamActionTypes } from "../constants/index";

const initialState = {
  loading: false,
  userList: null,
  blockedUnblockedUserById: null,
  createdUser: null,
  updatePasswordAdmin: null,
  updateAdminUser: null,
  deleteAdminUser: null,
  userPortalUserList: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UamActionTypes.get_AllUsers.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UamActionTypes.get_AllUsers.SUCCESS:
      return {
        ...state,
        loading: false,
        userList: payload,
      };
    case UamActionTypes.get_AllUsers.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case UamActionTypes.post_CreateUser.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UamActionTypes.post_CreateUser.SUCCESS:
      return {
        ...state,
        loading: false,
        createdUser: payload,
      };
    case UamActionTypes.post_CreateUser.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case UamActionTypes.post_BlockUnblockUser.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UamActionTypes.post_BlockUnblockUser.SUCCESS:
      return {
        ...state,
        loading: false,
        blockedUnblockedUserById: payload,
      };
    case UamActionTypes.post_BlockUnblockUser.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case UamActionTypes.post_UpdateAdminUserById.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UamActionTypes.post_UpdateAdminUserById.SUCCESS:
      return {
        ...state,
        loading: false,
        updatePasswordAdmin: payload,
      };
    case UamActionTypes.post_UpdateAdminUserById.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case UamActionTypes.put_UpdateAdminUserDetailsById.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UamActionTypes.put_UpdateAdminUserDetailsById.SUCCESS:
      return {
        ...state,
        loading: false,
        updateAdminUser: payload,
      };
    case UamActionTypes.put_UpdateAdminUserDetailsById.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case UamActionTypes.delete_AdminUser.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UamActionTypes.delete_AdminUser.SUCCESS:
      return {
        ...state,
        loading: false,
        deleteAdminUser: payload,
      };
    case UamActionTypes.delete_AdminUser.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case UamActionTypes.get_UserPortalUser.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UamActionTypes.get_UserPortalUser.SUCCESS:
      return {
        ...state,
        loading: false,
        userPortalUserList: payload,
      };
    case UamActionTypes.get_UserPortalUser.FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
