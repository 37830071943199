import ErrorPage from "views/Pages/ErrorPage.js";
import LoginPage from "../../views/Login/index";

import Image from "@material-ui/icons/Image";

var guestRoutes = [
  {
    collapse: true,
    name: "Pages",
    icon: Image,
    state: "pageCollapse",
    views: [
      {
        path: "/login-page",
        name: "Login Page",
        mini: "LP",
        component: LoginPage,
        layout: "/auth",
      },
      {
        path: "/forgotpassword",
        name: "Forget Password",
        mini: "FP",
        component: LoginPage,
        layout: "/auth",
      },
      {
        path: "/error-page",
        name: "Error Page",
        mini: "EP",
        component: ErrorPage,
        layout: "/auth",
      },
    ],
  },
];
export default guestRoutes;
