/**
 @description
 * This file contains generic functions. 
 * They are here in utility because we are using them across the application.
 */
import React from "react";
import history from "./history";
import Alert from "react-s-alert";
import { change } from "redux-form";

export let API_URL = process.env.REACT_APP_BASE_URL;

const REQUEST = "REQUEST";
const SUCCESS = "SUCCESS";
const FAILURE = "FAILURE";

export const TOKEN_EXPIRY_MESSAGE = "Session has expired. Login again!";

export const percentageRegExp = /^(?:\d*\.\d{1,2}|\d+)$/;
export const negativeNumberCheckRegex = /^\d+([.,]\d{1,2})?$/;
export const positiveIntegerRegex = /^[0-9]\d*$/;
export const phoneRegExp = /^[6-9]\d{9}$/;
export const bookletQuantityRegExp = /^[0-9]*$/;
export const panRegExp = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
export const gstRegExp = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
export const boolRegExp = /^(0|1)$/;
export const pinRegExp = /^[1-9][0-9]{5}$/;
export const emailRegExp = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
export const csvRegex = /(\.csv)$/i;
export const nameRegExp = /[a-zA-Z]{3,30}/;
export const speacialRegExp = /[!@#$%^&*(),.?":{}|<>'-]/;
export const numberRegExp = /[0-9]/;
export const aplhabetCheck = /[a-zA-Z]/;
export const panNoRegExp = /[0-9a-zA-Z]{10}/;
export const passwordRegExp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
export const manualGVRegExp = /^\d{14}$/;
export const scannedGVRegExp = /^\d{22}$/;
export const imageRegExp = /[\/.](jpg|jpeg|png)$/i;
export const imagePdfRegExp = /[\/.](jpg|jpeg|png|pdf)$/i;

export function actionCreator(actionType, data) {
  return {
    type: actionType,
    payload: data,
  };
}

export function createRequestActionTypes(base) {
  return [REQUEST, SUCCESS, FAILURE].reduce((requestTypes, type) => {
    requestTypes[type] = `${base}_${type}`;
    return requestTypes;
  }, {});
}

export function handleLoginRedirect(response, targetUrl) {
  localStorage.setItem("access_token", response.token);
  history.push(targetUrl);
}

export function handleLogoutRedirect() {
  localStorage.clear();
  localStorage.setItem("LoggedOut", true);
  window.location.pathname = "/auth/login-page";
}

export function checkHttpStatus(response) {
  if (response.status >= 200 && response.status < 204) {
    return response.json();
  } else if (response.status === 204) {
    return true;
  } else if (response.status >= 400 && response.status < 500) {
    return response.json();
  } else {
    var error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}

export const jsonApiHeader = (accessToken) => {
  return {
    "Content-Type": "application/json",
    Authorization: accessToken ? `Bearer ${accessToken}` : "",
  };
};

export const showSuccessMessage = (message, timeout) => {
  Alert.success(<b>{message}</b>, {
    position: "bottom-right",
    effect: "slide",
    timeout: timeout ? timeout : 3000,
  });
};

export const showErrorMessage = (message, timeout) => {
  Alert.error(<b>{message}</b>, {
    position: "bottom-right",
    effect: "slide",
    timeout: timeout ? timeout : 5000,
  });
};

export const showWarningMessage = (message, timeout) => {
  Alert.warning(<b>{message}</b>, {
    position: "bottom-right",
    effect: "slide",
    timeout: timeout ? timeout : 5000,
  });
};

export const ResetFormFields = (
  dispatch,
  formReducerName,
  FormFieldNameArray
) => {
  FormFieldNameArray.map((value) => {
    dispatch(change(formReducerName, value, null));
    return null;
  });
};
