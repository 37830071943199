import { emailRegExp, phoneRegExp } from "../../../../shared/utility";

const validate = (values, data) => {
  console.log("data", data);
  const errors = {};
  if (!values.roleId) {
    errors.roleId = "Role Required";
  }
  if (!values.name) {
    errors.name = "Name Required";
  }
  if (!values.email) {
    errors.email = "Email Required";
  }
  if (values.email && !emailRegExp.test(values.email)) {
    errors.email = "Invalid Email";
  }
  if (!values.password) {
    errors.password = "Password Required (min 8 digits, ex: Pass@123)";
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = "Confirm Password Required";
  }
  if (values.confirmPassword && values.password !== values.confirmPassword) {
    errors.confirmPassword = "Password Mismatched";
  }

  return errors;
};
export default validate;
