import { TaskActionTypes } from "../constants/index";

const initialState = {
  loading: false,
  uploadedTask: null,
  taskList: null,
  updateTask: null,
  deletedTask: null,
  userTaskList: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case TaskActionTypes.post_createTask.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TaskActionTypes.post_createTask.SUCCESS:
      return {
        ...state,
        loading: false,
        uploadedBanner: payload,
      };
    case TaskActionTypes.post_createTask.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case TaskActionTypes.get_taskList.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TaskActionTypes.get_taskList.SUCCESS:
      return {
        ...state,
        loading: false,
        taskList: payload,
      };
    case TaskActionTypes.get_taskList.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case TaskActionTypes.post_taskDelete.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TaskActionTypes.post_taskDelete.SUCCESS:
      return {
        ...state,
        loading: false,
        deletedTask: payload,
      };
    case TaskActionTypes.post_taskDelete.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case TaskActionTypes.post_updateTask.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TaskActionTypes.post_updateTask.SUCCESS:
      return {
        ...state,
        loading: false,
        updateTask: payload,
      };
    case TaskActionTypes.post_updateTask.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case TaskActionTypes.get_escalationList.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TaskActionTypes.get_escalationList.SUCCESS:
      return {
        ...state,
        loading: false,
        escalationList: payload,
      };
    case TaskActionTypes.get_escalationList.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case TaskActionTypes.get_taskByUserId.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TaskActionTypes.get_taskByUserId.SUCCESS:
      return {
        ...state,
        loading: false,
        userTaskList: payload,
      };
    case TaskActionTypes.get_taskByUserId.FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
