import React, { Component } from "react";
import { reduxForm, Field } from "redux-form";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import moment from "moment";
import MaterialTable from "material-table";

import ViewListIcon from "@material-ui/icons/ViewList";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { Paper } from "@material-ui/core";
import BlockIcon from "@material-ui/icons/Block";

import { Refresh } from "@material-ui/icons";
import { Edit, Delete } from "@material-ui/icons";
import VpnKeyIcon from "@material-ui/icons/VpnKey";

import styles from "../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import {
  GetAllUamAction,
  PostBlockUnblockUserByIdAction,
} from "./actions/UamActions";
import AddEditUamModal from "./Modal/AddEditUamModal";
import Loader from "../../../components/Loader/index";
import DeleteUserModal from "./Modal/DeleteUserModal";

class ManageUserRoles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        // { title: "Name", field: "name" },
        { title: "Role", field: "role" },
        { title: "Email", field: "email" },
        {
          title: "Status",
          field: "isActive",
          render: (rowData) =>
            rowData.isActive !== null ? (
              <span
                style={{ color: rowData.isActive === true ? "green" : "red" }}
              >
                {`${rowData.isActive}`}
              </span>
            ) : (
              "NA"
            ),
        },
      ],
      data: [],
      openAddEditUamModal: false,
      openDeleteUserModal: false,
      uamId: 0,
      type: "",
      status: "",
      userDetails: "",
    };
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    this.props.GetAllUamAction();
  };

  componentDidUpdate = (prevProps) => {
    if (
      this.props.userList !== undefined &&
      this.props.userList !== null &&
      this.props.userList !== prevProps.userList
    ) {
      this.setState({
        data: this.props.userList,
      });
    }
  };

  handleEditUamDetails = (event, rowData) => {
    let uamId = rowData.id;
    this.setState({
      uamId: uamId,
      type: "edit",
      openAddEditUamModal: true,
      userDetails: rowData,
    });
  };

  handleBlockUnblockUam = (event, rowData) => {
    this.setState({
      openDeleteUserModal: true,
      uamId: rowData.id,
      type: "block",
      status: rowData.isActive,
      userDetails: rowData,
    });
  };

  handleDeleteUam = (event, rowData) => {
    this.setState({
      openDeleteUserModal: true,
      uamId: rowData.id,
      type: "delete",
      status: rowData.isActive,
      userDetails: rowData,
    });
  };

  addUamClickHandler = () => {
    this.setState({
      openAddEditUamModal: true,
      type: "add",
    });
  };

  handleResetPassword = (event, rowData) => {
    let uamId = rowData.id;
    this.setState({
      uamId: uamId,
      type: "cp",
      openAddEditUamModal: true,
      userDetails: rowData,
    });
  };

  handleClose = () => {
    this.setState({
      openAddEditUamModal: false,
      openDeleteUserModal: false,
      uamId: 0,
      type: "",
      status: "",
      userDetails: "",
    });
  };

  render() {
    const { loading } = this.props;
    const {
      columns,
      data,
      openAddEditUamModal,
      uamId,
      type,
      openDeleteUserModal,
      status,
      userDetails,
    } = this.state;

    return (
      <>
        {loading && <Loader open={true} loaderMessage="Fetching..." />}
        {openAddEditUamModal && (
          <AddEditUamModal
            type={type}
            uamId={uamId}
            userDetails={userDetails}
            handleClose={() => this.handleClose()}
          />
        )}
        {openDeleteUserModal && (
          <DeleteUserModal
            uamId={uamId}
            type={type}
            status={status}
            userDetails={userDetails}
            handleClose={() => this.handleClose()}
          />
        )}
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader>
                <CardIcon color="rose" icon>
                  <ViewListIcon />
                </CardIcon>
                <h4>User Roles</h4>
              </CardHeader>
              <CardBody>
                <MaterialTable
                  components={{
                    Container: (props) => <Paper {...props} elevation={0} />,
                  }}
                  title=""
                  columns={columns}
                  data={data}
                  options={{
                    actionsColumnIndex: -1,
                    filtering: true,
                    headerStyle: {
                      backgroundColor: "#ccc",
                      color: "#000",
                      fontWeight: 600,
                    },
                    rowStyle: {
                      backgroundColor: "#EEE",
                    },
                    // grouping: true
                  }}
                  actions={[
                    {
                      icon: Refresh,
                      tooltip: "Refresh List",
                      isFreeAction: true,
                      onClick: () => this.loadData(),
                    },
                    {
                      icon: "add",
                      tooltip: "Add Role",
                      isFreeAction: true,
                      onClick: () => this.addUamClickHandler(),
                    },
                    (rowData) => ({
                      icon: Edit,
                      tooltip: "Edit Role Details",
                      disabled:
                        rowData.role.toUpperCase() === "SUPERADMIN"
                          ? true
                          : false,
                      onClick: (event, rowData) =>
                        this.handleEditUamDetails(event, rowData),
                    }),
                    (rowData) => ({
                      icon: VpnKeyIcon,
                      tooltip: "Reset Password",
                      disabled:
                        rowData.role.toUpperCase() === "SUPERADMIN"
                          ? true
                          : false,
                      onClick: (event, rowData) =>
                        this.handleResetPassword(event, rowData),
                    }),
                    (rowData) => ({
                      icon: BlockIcon,
                      tooltip: "Activate / Deactivet User Role",
                      disabled:
                        rowData.role.toUpperCase() === "SUPERADMIN"
                          ? true
                          : false,
                      onClick: (event, rowData) =>
                        this.handleBlockUnblockUam(event, rowData),
                    }),
                    (rowData) => ({
                      icon: Delete,
                      tooltip: "Delete User",
                      disabled:
                        rowData.role.toUpperCase() === "SUPERADMIN"
                          ? true
                          : false,
                      onClick: (event, rowData) =>
                        this.handleDeleteUam(event, rowData),
                    }),
                  ]}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

ManageUserRoles = reduxForm({
  form: "ManageUserRolesForm",
  enableReinitialize: true,
})(ManageUserRoles);

function mapStateToProps(state) {
  return {
    loading: state.uamReducer.loading,
    userList:
      state.uamReducer.userList !== null && state.uamReducer.userList.data,
    loggedInUserDetails:
      state.loginReducer.loggedInUserDetails !== null &&
      state.loginReducer.loggedInUserDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        GetAllUamAction,
        PostBlockUnblockUserByIdAction,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ManageUserRoles));
