/**
 @desc
 * Centralized unique actions for Uam Module.
 */

import {
  actionCreator,
  checkHttpStatus,
  jsonApiHeader,
  showErrorMessage,
  UamActionTypes,
  GET_ALL_USER_API,
  POST_CREATE_USER_API,
  POST_UPDATE_USER_API,
  POST_BLOCK_UNBLOCK_USER_BY_ID_API,
  POST_UPDATE_ADMIN_USER_PASSWORD_BY_ID_API,
  PUT_UPDATE_ADMIN_USER_BY_ID_API,
  DELETE_ADMIN_USER_API,
  GET_ALL_USER_PORTAL_USER_API,
} from "../constants/index";
import {
  showSuccessMessage,
  TOKEN_EXPIRY_MESSAGE,
} from "../../../../shared/utility";

export const GetAllUamAction = () => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.token;

    dispatch(actionCreator(UamActionTypes.get_AllUsers.REQUEST));
    fetch(`${GET_ALL_USER_API}`, {
      method: "GET",
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(UamActionTypes.get_AllUsers.FAILURE, response)
          );
          showErrorMessage(response.message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
          localStorage.clear();
          dispatch(
            actionCreator(UamActionTypes.get_AllUsers.FAILURE, response)
          );
          window.location.reload();
        } else {
          dispatch(
            actionCreator(UamActionTypes.get_AllUsers.SUCCESS, response)
          );
        }
      })
      .catch(function (error) {
        dispatch(actionCreator(UamActionTypes.get_AllUsers.FAILURE));
        console.log("error get_AllUsers ..", error);
      });
  };
};
export const GetUserPortalUserAction = () => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.token;

    dispatch(actionCreator(UamActionTypes.get_UserPortalUser.REQUEST));
    fetch(`${GET_ALL_USER_PORTAL_USER_API}`, {
      method: "GET",
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(UamActionTypes.get_UserPortalUser.FAILURE, response)
          );
          showErrorMessage(response.message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
          localStorage.clear();
          dispatch(
            actionCreator(UamActionTypes.get_UserPortalUser.FAILURE, response)
          );
          window.location.reload();
        } else {
          dispatch(
            actionCreator(UamActionTypes.get_UserPortalUser.SUCCESS, response)
          );
        }
      })
      .catch(function (error) {
        dispatch(actionCreator(UamActionTypes.get_UserPortalUser.FAILURE));
        console.log("error get_UserPortalUser ..", error);
      });
  };
};

export const PostBlockUnblockUserByIdAction = (payload) => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.token;

    dispatch(actionCreator(UamActionTypes.post_BlockUnblockUser.REQUEST));
    fetch(`${POST_BLOCK_UNBLOCK_USER_BY_ID_API}`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(
              UamActionTypes.post_BlockUnblockUser.FAILURE,
              response
            )
          );
          showErrorMessage(response.message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
          localStorage.clear();
          dispatch(
            actionCreator(
              UamActionTypes.post_BlockUnblockUser.FAILURE,
              response
            )
          );
          window.location.reload();
        } else {
          showSuccessMessage("User Status Updated Successfully");
          dispatch(
            actionCreator(
              UamActionTypes.post_BlockUnblockUser.SUCCESS,
              response
            )
          );
          dispatch(GetAllUamAction());
        }
      })
      .catch(function (error) {
        dispatch(actionCreator(UamActionTypes.post_BlockUnblockUser.FAILURE));
        console.log("error post_BlockUnblockUser ..", error);
      });
  };
};

export const PostAddUserAction = (postData) => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.token;

    dispatch(actionCreator(UamActionTypes.post_CreateUser.REQUEST));
    fetch(`${POST_CREATE_USER_API}`, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(UamActionTypes.post_CreateUser.FAILURE, response)
          );
          showErrorMessage(response.message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
          localStorage.clear();
          dispatch(
            actionCreator(UamActionTypes.post_CreateUser.FAILURE, response)
          );
          window.location.reload();
        } else {
          showSuccessMessage(`User Added Successfully`);
          dispatch(
            actionCreator(UamActionTypes.post_CreateUser.SUCCESS, response)
          );
          dispatch(GetAllUamAction());
        }
      })
      .catch(function (error) {
        dispatch(actionCreator(UamActionTypes.post_CreateUser.FAILURE));
        console.log("error post_CreateUser ..", error);
      });
  };
};

export const PutUpdateAdminUserDetailsAction = (postData) => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.token;

    dispatch(
      actionCreator(UamActionTypes.put_UpdateAdminUserDetailsById.REQUEST)
    );
    fetch(`${PUT_UPDATE_ADMIN_USER_BY_ID_API}`, {
      method: "PUT",
      body: JSON.stringify(postData),
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(
              UamActionTypes.put_UpdateAdminUserDetailsById.FAILURE,
              response
            )
          );
          showErrorMessage(response.message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
          localStorage.clear();
          dispatch(
            actionCreator(
              UamActionTypes.put_UpdateAdminUserDetailsById.FAILURE,
              response
            )
          );
          window.location.reload();
        } else {
          showSuccessMessage(`Profile Updated Successfully`);
          dispatch(
            actionCreator(
              UamActionTypes.put_UpdateAdminUserDetailsById.SUCCESS,
              response
            )
          );
          dispatch(GetAllUamAction());
        }
      })
      .catch(function (error) {
        dispatch(
          actionCreator(UamActionTypes.put_UpdateAdminUserDetailsById.FAILURE)
        );
        console.log("error put_UpdateAdminUserDetailsById ..", error);
      });
  };
};

export const PostUpdateAdminUserPasswordAction = (postData) => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.token;

    dispatch(actionCreator(UamActionTypes.post_UpdateAdminUserById.REQUEST));
    fetch(`${POST_UPDATE_ADMIN_USER_PASSWORD_BY_ID_API}`, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(
              UamActionTypes.post_UpdateAdminUserById.FAILURE,
              response
            )
          );
          showErrorMessage(response.message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
          localStorage.clear();
          dispatch(
            actionCreator(
              UamActionTypes.post_UpdateAdminUserById.FAILURE,
              response
            )
          );
          window.location.reload();
        } else {
          showSuccessMessage(`Password Updated Successfully`);
          dispatch(
            actionCreator(
              UamActionTypes.post_UpdateAdminUserById.SUCCESS,
              response
            )
          );
          dispatch(GetAllUamAction());
        }
      })
      .catch(function (error) {
        dispatch(
          actionCreator(UamActionTypes.post_UpdateAdminUserById.FAILURE)
        );
        console.log("error post_UpdateAdminUserById ..", error);
      });
  };
};

export const DeleteAdminUser = (postData) => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.token;

    dispatch(actionCreator(UamActionTypes.delete_AdminUser.REQUEST));
    fetch(`${DELETE_ADMIN_USER_API}`, {
      method: "DELETE",
      body: JSON.stringify(postData),
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(UamActionTypes.delete_AdminUser.FAILURE, response)
          );
          showErrorMessage(response.message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
          localStorage.clear();
          dispatch(
            actionCreator(UamActionTypes.delete_AdminUser.FAILURE, response)
          );
          window.location.reload();
        } else {
          showSuccessMessage(`User Deleted Successfully`);
          dispatch(
            actionCreator(UamActionTypes.delete_AdminUser.SUCCESS, response)
          );
          dispatch(GetAllUamAction());
        }
      })
      .catch(function (error) {
        dispatch(actionCreator(UamActionTypes.delete_AdminUser.FAILURE));
        console.log("error delete_AdminUser ..", error);
      });
  };
};
