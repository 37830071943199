import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import MaterialTable from "material-table";

import ViewListIcon from "@material-ui/icons/ViewList";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { Paper } from "@material-ui/core";

import { Refresh } from "@material-ui/icons";
import { Delete } from "@material-ui/icons";
import { Edit } from "@material-ui/icons";

import styles from "../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import Loader from "../../../components/Loader/index";
import { GetTaskListAction } from "./actions/TaskActions";
import AddEditTaskModal from "./Modal/TaskAddEditModal";
import DeleteTaskModal from "./Modal/DeleteTaskModal";

class TaskList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      taskId: 0,
      openAddEditTaskModal: false,
      taskDetails: "",
      type: "",
      openDeleteTaskModal: false,
    };
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    this.props.GetTaskListAction();
  };

  componentDidUpdate = (prevProps) => {
    if (
      this.props.taskList !== undefined &&
      this.props.taskList !== null &&
      this.props.taskList !== prevProps.taskList
    ) {
      this.setState({
        data: this.props.taskList,
      });
    }
  };

  addTaskClickHandler = () => {
    this.setState({
      openAddEditTaskModal: true,
      type: "add",
    });
  };

  handleEditTaskDetails = (event, rowData) => {
    let taskId = rowData._id;
    this.setState({
      taskId: taskId,
      taskDetails: rowData,
      openAddEditTaskModal: true,
      type: "edit",
    });
  };

  handleDeleteTask = (event, rowData) => {
    let taskId = rowData._id;
    this.setState({
      taskId: taskId,
      taskDetails: rowData,
      openDeleteTaskModal: true,
    });
  };

  handleClose = () => {
    this.setState({
      openAddEditTaskModal: false,
      openDeleteTaskModal: false,
      taskId: 0,
      taskDetails: "",
      type: "",
    });
  };

  render() {
    const { loading } = this.props;
    const {
      data,
      type,
      openAddEditTaskModal,
      openDeleteTaskModal,
      taskId,
      taskDetails,
    } = this.state;

    return (
      <>
        {loading && <Loader open={true} loaderMessage="Fetching..." />}
        {openAddEditTaskModal && (
          <AddEditTaskModal
            taskId={taskId}
            type={type}
            taskDetails={taskDetails}
            handleClose={() => this.handleClose()}
          />
        )}
        {openDeleteTaskModal && (
          <DeleteTaskModal
            taskId={taskId}
            type={type}
            taskDetails={taskDetails}
            handleClose={() => this.handleClose()}
          />
        )}
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader>
                <CardIcon color="rose" icon>
                  <ViewListIcon />
                </CardIcon>
                <h4>Task Status List</h4>
              </CardHeader>
              <CardBody>
                <MaterialTable
                  components={{
                    Container: (props) => <Paper {...props} elevation={0} />,
                  }}
                  title=""
                  columns={[
                    {
                      title: "Task Status Name",
                      field: "name",
                    },
                  ]}
                  data={data}
                  options={{
                    actionsColumnIndex: -1,
                    filtering: true,
                    sorting: true,
                    headerStyle: {
                      backgroundColor: "#ccc",
                      color: "#000",
                      fontWeight: 600,
                    },
                    rowStyle: {
                      backgroundColor: "#EEE",
                    },
                  }}
                  actions={[
                    {
                      icon: Refresh,
                      tooltip: "Refresh List",
                      isFreeAction: true,
                      onClick: () => this.loadData(),
                    },
                    {
                      icon: "add",
                      tooltip: "Add Role",
                      isFreeAction: true,
                      onClick: () => this.addTaskClickHandler(),
                    },
                    (rowData) => ({
                      icon: Edit,
                      tooltip: "Chnage Task Order",
                      disabled: rowData.isactive == "N" ? true : false,
                      onClick: (event, rowData) =>
                        this.handleEditTaskDetails(event, rowData),
                    }),
                    (rowData) => ({
                      icon: Delete,
                      tooltip: "Delete Task",
                      disabled: rowData.isactive == "Y" ? true : false,
                      onClick: (event, rowData) =>
                        this.handleDeleteTask(event, rowData),
                    }),
                  ]}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

TaskList = reduxForm({
  form: "TaskListForm",
  enableReinitialize: true,
})(TaskList);

function mapStateToProps(state) {
  return {
    loading: state.taskReducer.loading,
    taskList:
      state.taskReducer.taskList !== null && state.taskReducer.taskList.data,
    loggedInUserDetails:
      state.loginReducer.loggedInUserDetails !== null &&
      state.loginReducer.loggedInUserDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        GetTaskListAction,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TaskList));
