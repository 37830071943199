import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { Field, reduxForm, change } from "redux-form";

import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { Divider, MenuItem } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import {
  PostTaskCreateAction,
  PostTaskUpdateAction,
} from "../actions/TaskActions";
import { renderTextField } from "../../../../shared/reduxFields";
import validate from "./TaskAddEditModalValidate";

class AddEditTaskModal extends Component {
  componentDidMount = () => {
    if (this.props.type === "edit") {
      this.props.dispatch(
        change("AddEditTaskModalForm", "task", this.props.taskDetails.name)
      );
    }
  };

  onFormSubmitHandler = (formData) => {
    const postData = {
      name: formData.task,
    };
    if (this.props.type !== "edit") {
      this.props.PostTaskCreateAction(postData);
    } else {
      this.props.PostTaskUpdateAction(postData, this.props.taskId);
    }
    this.props.handleClose();
  };

  render() {
    const { classes, handleSubmit } = this.props;

    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <Dialog
              onClose={() => this.props.handleClose()}
              aria-labelledby="customized-dialog-title"
              open={() => this.props.handleOpen()}
            >
              <form
                onSubmit={handleSubmit((val) => this.onFormSubmitHandler(val))}
              >
                <MuiDialogTitle
                  className={classes.modelTitle}
                  id="customized-dialog-title"
                  onClose={() => this.props.handleClose()}
                >
                  <h6>
                    {this.props.type === "edit" ? "Edit" : "Add"} Task Status
                  </h6>
                </MuiDialogTitle>
                <Divider />
                <MuiDialogContent>
                  <GridContainer>
                    <GridItem xs={12}>
                      <Field
                        id="task"
                        name="task"
                        label="Enter Task Status"
                        required={false}
                        component={renderTextField}
                      />
                    </GridItem>
                  </GridContainer>
                </MuiDialogContent>
                <MuiDialogActions>
                  <Button simple type="submit" color="rose">
                    SUBMIT
                  </Button>
                  <Button link onClick={() => this.props.handleClose()}>
                    CLOSE
                  </Button>
                </MuiDialogActions>
              </form>
            </Dialog>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

AddEditTaskModal = reduxForm({
  form: "AddEditTaskModalForm",
  validate,
  enableReinitialize: true,
})(AddEditTaskModal);

function mapStateToProps(state) {
  return {
    loggedInUserDetails:
      state.loginReducer.loggedInUserDetails !== null &&
      state.loginReducer.loggedInUserDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        PostTaskCreateAction,
        PostTaskUpdateAction,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AddEditTaskModal));
