import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form"; // SAYING use redux form reducers as reducers

import loginReducer from "../views/Login/reducers/LoginReducers";
import uamReducer from "../views/Modules/UAM/reducers/UamReducers";
import taskReducer from "../views/Modules/Task/reducers/TaskReducers";

import UamReducer from "../views/Modules/UAM/reducers/UamReducers";

export default combineReducers({
  form: formReducer,
  loginReducer: loginReducer,
  uamReducer: uamReducer,
  taskReducer: taskReducer,
  uamReducer: UamReducer,
});
