// @material-ui/icons
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import DashboardIcon from "@material-ui/icons/Dashboard";
import BusinessIcon from "@material-ui/icons/Business";
import ColorLensIcon from "@material-ui/icons/ColorLens";
import StoreIcon from "@material-ui/icons/Store";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
import AmpStoriesIcon from "@material-ui/icons/AmpStories";
import FormatStrikethroughIcon from "@material-ui/icons/FormatStrikethrough";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import LineWeightIcon from "@material-ui/icons/LineWeight";
import StyleIcon from "@material-ui/icons/Style";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import CompareIcon from "@material-ui/icons/Compare";
import BrandingWatermarkIcon from "@material-ui/icons/BrandingWatermark";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import CropIcon from "@material-ui/icons/Crop";
import BlurLinearIcon from "@material-ui/icons/BlurLinear";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import LocalPrintshopIcon from "@material-ui/icons/LocalPrintshop";
import DoneIcon from "@material-ui/icons/Done";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import ReceiptIcon from "@material-ui/icons/Receipt";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import DateRangeIcon from "@material-ui/icons/DateRange";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";

import Dashboard from "../../views/Modules/Dashboard/Dashboard";

import ManageUser from "../../views/Modules/UAM/ManageUser";
import UserPortalUser from "../../views/Modules/UAM/UserPortalUser";

import TaskList from "../../views/Modules/Task/TaskList";
import EscalationList from "../../views/Modules/Task/EscalationList";
import UserTaskList from "../../views/Modules/Task/UserTasks";

var adminRoutes = [
  // {
  // collapse: true,
  //  name: "Dashboard",
  //  icon: DashboardIcon,
  //  state: "dashboardCollapse",
  //  views: [
  //    {
  //      path: "/dashboard",
  //      name: "Dashboard",
  //     mini: "DSB",
  //      component: Dashboard,
  //      layout: "/admin",
  //    },
  //  ],
  // },
   {
    collapse: true,
    name: "Task Status Master",
    icon: BrandingWatermarkIcon,
    state: "bannerCollapse",
    views: [
      {
        path: "/taskstatusmaster",
        name: "Task Master List",
        mini: "TML",
        component: TaskList,
        layout: "/admin",
      },
      {
        path: "/escalationlist",
        name: "Escalation/Overdue List",
        mini: "EOL",
        component: EscalationList,
        layout: "/admin",
      },
      {
        path: "/usertasklist",
        name: "User Tasks List",
        mini: "UTL",
        component: UserTaskList,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Manage Roles",
    icon: PeopleAltIcon,
    state: "uramCollapse",
    views: [
      {
        path: "/roleaccess",
        name: "User Role Access",
        mini: "URA",
        component: ManageUser,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "User Portal",
    icon: PeopleAltIcon,
    state: "upuCollapse",
    views: [
      {
        path: "/userportaluser",
        name: "User Portal User",
        mini: "UPU",
        component: UserPortalUser,
        layout: "/admin",
      },
    ],
  },
];
export default adminRoutes;
